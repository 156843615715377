import React, { Component } from 'react';

import Vimeo from '@u-wave/react-vimeo';

import '../css/ProjectPage.css';
import BackIcon from '../icons/back.svg';

export default class ProjectPage extends Component {

	constructor(props) {
    super(props);

    this.id = this.props.match.params.project;
    this.state = { page: null };
  }

  componentDidMount() {
    this.props.onMounted();
  }

  componentWillUnmount() {
    if (this.props.pageUnmounted != null) {
      this.props.pageUnmounted();      
    }
  }

  render() {
    return (
      <div className="project-page">

        <div className="title-row">
          <img src={BackIcon} alt="back" className="back"
            onClick={this.goBack.bind(this)}/>

          <p className="title">The Brentford Project</p>
        </div>

        <Vimeo responsive={true} 
          video="https://player.vimeo.com/video/376380811" />

        <p className="with">With: <a href="https://neutral.digital/">Neutral Digital</a></p>
        <p className="technologies">Technologies: <span>Unity3D</span>, <span>3D Projection</span>, <span>Networking</span>, <span>iOS</span></p>

        <p>
        We were approached by the client to create a custom application that included a rich UI environment, complex networking functionality and a lot of media, in a tight timeline. So we knew we had to take this on.
        <br></br><br></br>
        It’s crucial for large-scale residential property development projects to secure forward sales. In an industry that is as competitive and with such high stakes as property, developers need to exhibit their upcoming projects in a way that excites prospective buyers years before the works are even finished.
        </p>
        <img src="/media/images/ND_x_Ballymore_Brentford_PR_Image_8_Web.jpg" />

        <p>
        The solution we created exemplifies the values and style of the The Brentford Project — an interactive digital experience that showcases all aspects of the development on an huge 4-metre touch screen and iPad.
        <br></br><br></br>
        The goal was to deliver an industry-leading digital experience for Ballymore’s on-site marketing suite in London as well as sales events around the world. Key objectives were:
        <br></br><br></br>
        Targeting an expansive set of devices from the huge video wall all the way down to iPads.
        Localising the application to cater to both English and Chinese audiences.
        </p>

        <img src="/media/images/ND_x_Ballymore_Brentford_PR_Image_5_Web-1.jpg" />

        <img src="/media/images/ND_x_Ballymore_Brentford_PR_Image_8_Web.jpg" />

        <img src="/media/images/ND_x_Ballymore_Brentford_PR_Image_11_Web.jpg" />        
      </div>
    );
  }

  goBack() {
    this.props.history.push("/");
  }

}