import React, { Component } from 'react';

import Vimeo from '@u-wave/react-vimeo';

import '../css/ProjectPage.css';
import BackIcon from '../icons/back.svg';

export default class ZslProjectPage extends Component {

	constructor(props) {
    super(props);

    this.id = this.props.match.params.project;
    this.state = { page: null };
  }

  componentDidMount() {
    this.props.onMounted();
  }

  componentWillUnmount() {
    if (this.props.pageUnmounted != null) {
      this.props.pageUnmounted();      
    }
  }

  render() {
    return (
      <div className="project-page">

        <div className="title-row">
          <img src={BackIcon} alt="back" className="back"
            onClick={this.goBack.bind(this)}/>

          <p className="title">ZSL's Thames VR Experience</p>
        </div>

        <Vimeo responsive={true} 
          video="https://player.vimeo.com/video/359055254" />

        <p className="with">With: <a href="https://byo.works/">BYO</a>, <a href="https://somewhereelse.co/">Somewhere Else</a></p>
        <p className="technologies">Technologies: <span>VR</span>, <span>Unity3D</span></p>

        <p>
        The Thames is one of the cleanest and most biodiverse rivers in Europe.
        
        <br></br><br></br>
        But visitors and residents just can't see past the brown! They have no idea that there are sharks, seahorses, eels, seals and much more swimming about down there. ZSL wanted to educate people about the river's biodiversity, and inspire young and old to contribute to it's continued success as a conservation project.
        </p>

        <p>
        To help bring this education to the river, we built ZSL the UKs first permanent, unmanned, outdoor (and free!) VR installation.
        
        <br></br><br></br>
        
        Installed on The Queens Walk in London Bridge, curious passers by are given the opportunity to join a ZSL dive team and identify species that live under the surface. Every single dive is unique, so visitors can compare what they saw afterwards with their friends.
        
        <br></br><br></br>

        To top it off, we put in 'tap to donate' hardware, allowing the installation to raise money for the great work ZSL do around the world.
        </p>
        
        <p>
        The aim of the campaign is awareness and as a technology first for the UK, it's making quite the splash ;).
        </p>

      </div>
    );
  }

  goBack() {
    this.props.history.push("/");
  }

}