import React, { Component } from 'react';

import '../css/Pill.css';

export default class Pill extends Component {
	constructor(props) {
		super(props);

		this.filterToggle = this.props.onFilterToggle.bind(this);
		this.state = { active: false }
	}


	render() {
		const cssClass = "pill shadow " + (this.state.active ? "selected" : "");

		return (
			<button 
				className={cssClass}
				onClick={this.handleClick.bind(this)}
			>{this.props.text}</button>
		)
	}

	handleClick() {
		this.setState({active: !this.state.active})
		this.filterToggle(this.props.text);
	}

}