import React, { Component } from 'react';

import gsap from 'gsap';

import '../css/Project.css';
import Star from '../icons/star-filled.svg';

const lineHeight = 20;
const collapsedHeight = 158;
const descriptionContainerHeight = 165;

export default class Project extends Component {
	constructor(props) {
		super(props);

		this.project = this.props.project;
    this.roles = this.project.role.split(",");
    this.technologies = this.project.tech.split(",");
    this.partners = this.project.partner.split(",");

    this.descriptionRef = React.createRef();

    this.state = { hasMore: false }

    this.rolesList = this.roles.map((item, index) => {
      return <span key={index}>{item}</span>
    }).reduce((prev, curr) => [prev, ', ', curr]);

    this.techList = this.technologies.map((item, index) => {
      return <span key={index}>{item}</span>
    }).reduce((prev, curr) => [prev, ', ', curr]);

    this.partnersList = this.partners.map((item, index) => {
      return <span key={index}>{item}</span>
    }).reduce((prev, curr) => [prev, ', ', curr]);
  }

  componentDidMount() {
    this.fullDescriptionHeight = this.descriptionRef.clientHeight + lineHeight;

    if (this.descriptionRef.clientHeight > descriptionContainerHeight && !this.project.hasContent) {
      this.setState({ hasMore: true, collapsed: true, moreText: "Expand" })
    }

    this.descriptionRef.style.setProperty("--description-height", "158px");
  }

 render() {
  return (
    <div>
      <div className="project-container right">

        <img className="project-image" src={this.project.image} alt="project"/>

        <div className="project-details">
          <div>
            <h2 ref={e => (this.titleRef = e)}><a className="orange-link" href={this.project.project_url} target="_blank" rel="noreferrer noopener">{this.project.title}</a>
            {this.project.hasStar && <span className="tooltip"><img src={Star} alt="star" />
            {this.project.starTooltip.length > 0 && <span className="tooltiptext">{this.project.starTooltip}</span>}</span>}
          </h2>

          <div className="description-container" ref={el => this.descriptionRef = el}>
            <p className="project-description fade">{this.project.description}</p>

            { this.project.hasContent
            && <button className="read-more" onClick={this.openProjectPage.bind(this)}>See More</button> }

            { this.state.hasMore
            && <button className="read-more" onClick={this.handleDescription.bind(this)}>{this.state.moreText}</button> }
          </div>

          </div>
        </div>

      </div>

      <div className="project-caption">
        <p>Role: {this.rolesList}</p>
        <p>With: {this.partnersList}</p>
        <p>Technologies: {this.techList}</p>
      </div>

    {!this.props.lastItem && <div className="divider" />}

    </div>
  )
}

openProjectPage() {
  this.props.onOpenProject(this.project);
}

handleDescription() {
  const toHeight = this.state.collapsed ? this.fullDescriptionHeight : collapsedHeight;
  const moreText = this.state.collapsed ? "Collapse" : "Expand";

  this.setState({ collapsed: !this.state.collapsed, moreText: moreText});

  gsap.to(this.descriptionRef, { "--description-height": toHeight + "px", duration: 0.5 });
}
}

