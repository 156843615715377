import React, { Component } from 'react';

import Project from './Project';
import Pill from './Pill';

import '../css/Home.css';

export default class Home extends Component {
	constructor(props) {
		super(props);

		this.state = { filters: [], projects: [] };
	}

	componentDidMount() {
		try {
			fetch("https://triantaf.com/api/projects/")
				.then((response) => response.json())
				.then((data) => {
					this.setState({ projects: data });
				})
		} catch (error) {

		}
	}

	render() {
		const items = this.state.projects.filter((item) => {
			if (this.state.filters.length === 0) return true;

			for (var i = 0; i < this.state.filters.length; i++) {
				if (item.tech.includes(this.state.filters[i])) return true;
			}

			return false;
		})
		.map((item, index, filtersItems) => {
			const lastItem = index === filtersItems.length - 1;

			return (
				<Project key={item.title}
					project={item} lastItem={lastItem}
					onOpenProject={this.openProject.bind(this)} />
			)
		})

		return (
			<div className="home">

				<h1 className="name">Christos Triantafyllis</h1>

				<div className="description">
					<p>I am a software developer and a generalist engineer (MSc).</p>

					<p>Working with leading digital agencies around the world to deliver projects for clients like <span class="client">Meta</span>, <span class="client">Siemens</span>, <span class="client">KPMG</span>, <span class="client">Harrods</span>, <span class="client">Major League Soccer</span>, <span class="client">University of Toronto</span> using <span class="technology">Unity</span>, <span class="technology">Kotlin</span>, <span class="technology">Swift</span>, <span class="technology">React Native</span>, <span class="technology">Python</span>, <span class="technology">React</span>, <span class="technology">Angular</span>, <span class="technology">Virtual Reality</span>, <span class="technology">Augmented Reality</span> and many more.</p>
					
					<p>If you want to work together, <a className="orange-link" href="#" onClick={this.openEmail.bind(this)}>get in touch</a>.</p>
				</div>

				<h1 className="work-header">Work</h1>

				<div className="control-div">
					<p className="filter-hint">Filter by:</p>

					<Pill onFilterToggle={this.handleFilter.bind(this)} text={"Unity3D"} />
					<Pill onFilterToggle={this.handleFilter.bind(this)} text={"Mobile"} />
					<Pill onFilterToggle={this.handleFilter.bind(this)} text={"VR"} />
					<Pill onFilterToggle={this.handleFilter.bind(this)} text={"AR"} />
					<Pill onFilterToggle={this.handleFilter.bind(this)} text={"React"} />
				</div>

				<div className="projects-list">
					{items}
				</div>

				<div className="contact-row">
					<a href="#" onClick={this.openEmail}>Email</a> / <a href="https://www.linkedin.com/in/christos-triantafyllis-61352b5b/" target="_blank" rel="noreferrer noopener">Linkedin</a>
				</div>

			</div>
		);
	}

	handleFilter(filter) {
		const filters = this.state.filters;
		const index = filters.indexOf(filter);

		if (index > -1) {
			filters.splice(index, 1);
		}
		else {
			filters.push(filter);
		}

		this.setState({ filters: filters });
	}

	openProject(projectTitle, project) {
		this.props.onOpenProject(projectTitle, project);
	}

	openEmail(e) {
		e.preventDefault();

		// ReactGA.event({
		//   category: 'User',
		//   action: 'Mail clicked'
		// });

		window.location.href = "mailto:triantafyllis.christos+portfolio@gmail.com"
	}

}