import React from 'react';

import { Route, Router, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import gsap from 'gsap';

import Home from './jsx/Home';
import ProjectPage from './jsx/ProjectPage';
import ZslProjectPage from './jsx/ZslProjectPage';

const pathHistory = createBrowserHistory();

export default class App extends React.Component {
	constructor(props) {
		super(props);

		this.overlayRef = React.createRef();
		this.overlayTitleRef = React.createRef();
	}

	render() {
		return (
			<div>

				<Router history={pathHistory}>
					<Switch>
						<Route exact path="/" 
							render={() => <Home onOpenProject={this.openProject.bind(this)} />} />

						<Route path="/projects/ballymore/"
							render={(props) => 
								<ProjectPage 
									project={this.currentProject}
									onMounted={this.pageMounted.bind(this)}
									onUnmounted={this.pageUnmounted.bind(this)}
									{...props}/>} />

						<Route path="/projects/zsl/"
							render={(props) => 
								<ZslProjectPage 
									project={this.currentProject}
									onMounted={this.pageMounted.bind(this)}
									onUnmounted={this.pageUnmounted.bind(this)}
									{...props}/>} />
					</Switch>  
				</Router>

				<div className="overlay" ref={this.overlayRef}>
					<div ref={this.overlayTitleRef} className="overlay-title"></div>
				</div>

			</div>
		);
	}

	openProject(project) {
		this.overlayTitleRef.current.textContent = project.title;

		gsap.to(this.overlayRef.current, { opacity: 1, duration: 0.3, delay: 0.1 });
		
		this.currentProject = project;

		setTimeout(() => {
			pathHistory.push("projects/" + this.currentProject.contentUrl);
		}, 400);
		
	}

	pageMounted() {
		setTimeout(() => {
			gsap.to(this.overlayRef.current, { opacity: 0 });
		}, 1000);
	}

	pageUnmounted() {
		this.currentProject = null;
	}

}